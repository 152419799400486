<template>
	<div>
		<div class="card d-flex flex-row justify-content-between p-2">
			<div class="d-flex align-items-start container-text">
				<div class="d-flex flex-column mr-1 select h-100">
					<v-select
						label="name"
						v-model="rangeSelect"
						:options="ranges"
						:clearable="false"
						:reduce="(option) => option.id"
						transition=""
						:placeholder="`Rangos`"
						v-b-tooltip.hover
						title="Rango"
						@input="(e) => setRanges(e)"
						:style="{ height: '100%', width: '180px' }"
					>
						<template slot="no-options"> Sin rangos encontrados </template>

						<template slot="option" slot-scope="option">
							<div class="d-center">
								<p class="mb-0" :style="{ marginLeft: `${(option.level - 1) * 15}px` }">
									{{ option.name }}
								</p>
							</div>
						</template>
						<template slot="selected-option" slot-scope="option">
							<div class="selected d-flex align-items-center" :style="{ height: '28px' }">
								<p class="mb-0">{{ option.name }}</p>
							</div>
						</template>
					</v-select>
					<!-- <small class="font-small-2 text-secondary mt-25">rango de fechas(gastos)</small> -->
				</div>
				<div class="d-flex align-items-center">
					<b-form-datepicker
						placeholder="Inicio"
						:date-format-options="{
							year: 'numeric',
							month: 'numeric',
							day: 'numeric',
						}"
						locale="es"
						v-model="dateIn"
						:max="dateFn"
						v-b-tooltip.hover
						title="Fecha de inicio"
						:style="{ width: '180px' }"
						class="mr-1"
						@input="RELOAD_CATEGORIES(true)"
					/>
					<b-form-datepicker
						placeholder="Inicio"
						:date-format-options="{
							year: 'numeric',
							month: 'numeric',
							day: 'numeric',
						}"
						locale="es"
						v-model="dateFn"
						:min="dateIn"
						v-b-tooltip.hover
						title="Fecha final"
						:style="{ width: '180px' }"
						@input="RELOAD_CATEGORIES(true)"
					/>
				</div>
			</div>
			<div class="d-flex">
				<button class="btn btn-primary d-flex align-items-center" @click="modalViewItems.show = true">
					<FeatherIcon class="mr-25" icon="ListIcon" size="18" /> Productos
				</button>
				<button
					class="btn btn-primary ml-1 d-flex align-items-center"
					@click="modalViewAddCategory.show = true"
				>
					<FeatherIcon class="mr-25" icon="PlusIcon" size="18" /> Nueva categoria
				</button>
			</div>
		</div>

		<div class="w-25">
			<AccordionSelectCategories :dateIn="dateIn" :dateFn="dateFn" v-if="dateIn && dateFn" />
		</div>
		<!-- 
		<div class="mt-4 mb-4 d-none" >
			<h2>Accordeon</h2>

			<div>
				<div
					class="accordeon card mb-0"
					v-for="(item, index) in arrayCategory"
					:key="index"
					:style="{ marginLeft: `${(item.level - 1) * 40}px` }"
				>
					<div class="d-flex title" @click="item.show = !item.show">
						<svg
							width="19.2"
							height="19.2"
							viewBox="0 0 16 16"
							class="caretIcon"
							style="shape-rendering: geometricprecision"
						>
							<path
								fill-rule="evenodd"
								fill="currentColor"
								d="M4.80153237,6.00002 L11.1984676,6.00002 C11.8579881,6.00002 12.2338734,6.76912179 11.8387119,7.30861993 L8.64024427,11.6724979 C8.3194337,12.1091674 7.6805663,12.1091674 7.3611326,11.6724979 L4.1612881,7.30861993 C3.76612658,6.76912179 4.14201193,6.00002 4.80153237,6.00002"
							></path>
						</svg>
						<p class="mb-0">{{ item.label }} => level: {{ item.level }}</p>
					</div>
					<div class="body" :class="[item.show ? 'active-accordeon' : '']">
						<h3>Contenido</h3>
						<button class="btn btn-primary ml-1">Agregar categoria</button>
					</div>
				</div>
			</div>
		</div> -->

		<div class="card mt-2 mx-auto">
			<b-table-simple class="table mb-0 shadow">
				<b-thead>
					<b-tr class="text-center">
						<b-th> # </b-th>
						<b-th> Nombre </b-th>
						<b-th> Descripcion </b-th>
						<b-th> TOTAL S/. </b-th>
						<b-th> TOTAL $ </b-th>
						<b-th> Acciones </b-th>
					</b-tr>
				</b-thead>
				<b-tbody>
					<b-tr class="text-center" v-for="(item, index) in arrayCategory.filter((it) => it.id)" :key="index">
						<template v-if="arrayCategory.length > 0">
							<b-td class="">
								<p class="mb-0">{{ index + 1 }}</p>
							</b-td>
							<b-td class="text-center" :style="{ width: '400px' }">
								<div class="d-flex align-items-center justify-content-center">
									<p
										class="mb-0 text-left w-50"
										:style="{ marginLeft: `${(item.level - 1) * 60}px` }"
									>
										{{ item.label }}
									</p>
								</div>
							</b-td>
							<b-td class="" :style="{ width: '600px' }">
								<p
									class="mb-0"
									v-if="item.description"
									v-b-tooltip.hover
									:title="item.description.length > 60 ? item.description : null"
								>
									{{
										item.description.length > 60
											? `${item.description.slice(0, 60)}...`
											: item.description
									}}
								</p>
								<p v-else class="mb-0">---</p>
							</b-td>
							<b-td class="">{{ item.total_s }}</b-td>
							<b-td class="">{{ item.total_d }}</b-td>
							<b-td class="">
								<FeatherIcon
									@click="
										modalViewDetails.show = true;
										modalViewDetails.category = item;
										modalViewDetails.title = item.label;
									"
									icon="ShoppingBagIcon"
									size="20"
									class="text-dark cursor-pointer"
									v-b-tooltip.hover
									title="Ver detalles"
								/>

								<FeatherIcon
									@click="openEditModal(item)"
									icon="EditIcon"
									size="20"
									class="text-primary cursor-pointer mr-50 ml-50"
									v-b-tooltip.hover
									title="Editar"
								/>
								<FeatherIcon
									@click="deleteCategory(item)"
									icon="Trash2Icon"
									size="20"
									class="text-danger cursor-pointer"
									v-b-tooltip.hover
									title="Eliminar"
								/>
							</b-td>
						</template>
					</b-tr>
					<b-tr v-if="arrayCategory.length == 0">
						<b-td colspan="7" class="text-center">
							<p class="mb-0 font-weight-bold">Sin categorias encontrados</p>
						</b-td>
					</b-tr>
				</b-tbody>

				<b-tfoot>
					<b-tr class="text-center">
						<b-th></b-th>
						<b-th></b-th>
						<b-th>Total</b-th>
						<b-th>
							{{
								sumTotal(
									arrayCategory.filter((it) => it.id),
									"total_s"
								).toFixed(2)
							}}
						</b-th>
						<b-th>
							{{
								sumTotal(
									arrayCategory.filter((it) => it.id),
									"total_d"
								).toFixed(2)
							}}
						</b-th>
						<b-th></b-th>
					</b-tr>
				</b-tfoot>
			</b-table-simple>
		</div>

		<b-modal
			v-if="modalViewAddCategory.show"
			v-model="modalViewAddCategory.show"
			:title="modalViewAddCategory.title"
			hide-footer
			@hide="refreshCategoryId"
		>
			<AddMgtCategory @refresh="refresh" />
		</b-modal>

		<b-modal
			v-if="modalViewEditCategory.show"
			v-model="modalViewEditCategory.show"
			:title="modalViewEditCategory.title"
			hide-footer
		>
			<EditMgtCategory @refresh="refresh" v-if="modalViewEditCategory.show" :item="modalViewEditCategory.data" />
		</b-modal>

		<b-sidebar
			v-model="modalViewItems.show"
			:title="modalViewItems.title"
			left
			width="90%"
			sidebar-class="sidebar-class"
			body-class="p-2 bg-white"
		>
			<ItemListBrain v-if="modalViewItems.show" />
		</b-sidebar>
		<b-sidebar
			v-if="modalViewDetails.show"
			v-model="modalViewDetails.show"
			:title="modalViewDetails.title"
			left
			width="90%"
			sidebar-class="sidebar-class"
			body-class="p-2 bg-white"
		>
			<DetailsCategory
				:category="modalViewDetails.category"
				:dateIn="dateIn"
				:dateFn="dateFn"
				v-if="dateIn && dateFn"
				@setRanges="setRanges"
				:rangeSelect="rangeSelect"
			/>
		</b-sidebar>
	</div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import AddMgtCategory from "./AddMgtCategory.vue";
import EditMgtCategory from "./EditMgtCategory.vue";
import mgtCategoryService from "@/services/mgt-category.service";
// import ItemListBrain from "../../items/components/ItemListBrain.vue";
import ItemListBrain from "../../../../items/components/ItemListBrain.vue";
import AccordionSelectCategories from "./AccordionSelectCategories.vue";
import DetailsCategory from "./DetailsCategory.vue";
import moment from "moment";

export default {
	name: "MgtCategoryList",
	components: { AddMgtCategory, EditMgtCategory, ItemListBrain, AccordionSelectCategories, DetailsCategory },
	data() {
		return {
			categories: [],
			levels: [],
			modalViewAddCategory: {
				show: false,
				title: "Nueva categoria",
			},
			modalViewEditCategory: {
				show: false,
				title: "Editar categoria",
				data: null,
			},
			modalViewItems: {
				show: false,
				title: "Lista de productos",
			},
			dateIn: null,
			dateFn: null,
			ArrayselectTypesDate: [],
			selectTypesDate: null,
			modalViewDetails: {
				show: false,
				title: "Insumos por categoria",
				category: null,
			},
			ranges: [
				{
					id: 1,
					name: "Semana actual",
				},
				{
					id: 2,
					name: "Quincena",
				},
				{
					id: 3,
					name: "Mes actual",
				},
				{
					id: 4,
					name: "Año actual",
				},
			],
			rangeSelect: 3,
		};
	},
	async mounted() {
		this.isPreloading();

		const date = new Date();
		const month = date.getMonth();
		const year = date.getFullYear();
		this.dateIn = `${year}-${month + 1}-01`;
		this.dateFn = `${year}-${month + 1}-${new Date(year, month + 1, 0).getDate()}`;
	},
	computed: {
		...mapState("orders", ["arrayCategory"]),
		sumTotal: () => (items, key) => {
			return items.reduce(function (previousValue, currentValue) {
				return previousValue + parseFloat(currentValue[key]);
			}, 0);
		},
	},
	methods: {
		...mapMutations("orders", ["RELOAD_CATEGORIES", "FILTER_CATEGORY_ARRAY", "SET_SEARCH_ITEMS"]),
		openEditModal(item) {
			this.modalViewEditCategory.show = true;
			this.modalViewEditCategory.data = item;
		},
		async deleteCategory(item) {
			const { isConfirmed } = await this.showConfirmSwal();
			if (!isConfirmed) return;
			this.isPreloading();
			const { status } = await mgtCategoryService.deleteMgtCategoryByModule({ id: item.id });
			if (status) {
				this.FILTER_CATEGORY_ARRAY(item.id);
			}

			this.isPreloading(false);
		},
		async refresh() {
			this.modalViewAddCategory.show = false;
			this.modalViewEditCategory.show = false;
		},
		async setRanges(e) {
			switch (e) {
				case 1:
					this.dateIn = moment().startOf("week").format("YYYY-MM-DD");
					this.dateFn = moment().endOf("week").format("YYYY-MM-DD");
					this.RELOAD_CATEGORIES(true);
					break;
				case 2:
					this.dateIn = moment().startOf("month").format("YYYY-MM-DD");
					this.dateFn = moment().startOf("month").add(14, "days").format("YYYY-MM-DD");
					this.RELOAD_CATEGORIES(true);
					break;
				case 3:
					this.dateIn = moment().startOf("month").format("YYYY-MM-DD");
					this.dateFn = moment().endOf("month").format("YYYY-MM-DD");
					this.RELOAD_CATEGORIES(true);
					break;
				case 4:
					this.dateIn = moment().startOf("year").format("YYYY-MM-DD");
					this.dateFn = moment().endOf("year").format("YYYY-MM-DD");
					this.RELOAD_CATEGORIES(true);
					break;

				default:
					this.dateFn = null;
					this.dateIn = null;
					break;
			}
			this.rangeSelect = e;
			// console.log("date", this.dateIn, this.dateFn);

			// dateIn
			// dateFn
		},
		refreshCategoryId() {
			this.SET_SEARCH_ITEMS({
				key: "category_id",
				value: null,
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.item-category {
	width: 200px;
	margin-bottom: 1rem !important;
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-between;
	padding: 1rem;
	z-index: 2;
	p {
		width: 200px !important;
	}
}
.container-categories {
	position: relative;
	padding: 4rem 0 2rem 0;
	.container-lines {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 120px;
		.line {
			height: 100%;
			width: 1px;
			border: none;
			border-left: 1px dashed #666666;
		}
		.number {
			// background: red;
			text-align: center;
			font-size: 20px;
			margin-bottom: -2rem !important;
			font-weight: bolder;
		}
	}
	.text-level {
		width: 120px;
		// background: green;
		position: absolute;
		top: 0;
		left: -120px;
		text-align: center;
		font-size: 20px;
		font-weight: bolder;
	}
}
.container-text {
	position: relative;
	// width: ;
	small {
		position: absolute;
		top: 90%;
		left: 0;
	}
	.select {
		width: 180px;
	}
}

.accordeon {
	border: none;
	box-shadow: none;
	.title {
		padding: 1rem;
		cursor: pointer;
	}
	.body {
		background: gray;
		height: 0 !important;
		overflow: hidden;
		padding: 0;
	}
	.active-accordeon {
		height: auto !important;
		overflow: auto;
		padding: 2rem;
	}
}
.select-100 {
	height: 100% !important;
	background: green;
}
</style>
