<template>
	<div>
		<div class="d-flex align-items-start container-text">
			<div class="d-flex flex-column mr-1 select h-100">
				<!-- {{ selectRange }} -->
				<v-select
					label="name"
					v-model="selectRange"
					:options="ranges"
					:clearable="false"
					:reduce="(option) => option.id"
					transition=""
					:placeholder="`Rangos`"
					v-b-tooltip.hover
					title="Rango"
					@input="(e) => setRanges(e)"
					:style="{ height: '100%', width: '180px' }"
				>
					<template slot="no-options"> Sin rangos encontrados </template>

					<template slot="option" slot-scope="option">
						<div class="d-center">
							<p class="mb-0" :style="{ marginLeft: `${(option.level - 1) * 15}px` }">
								{{ option.name }}
							</p>
						</div>
					</template>
					<template slot="selected-option" slot-scope="option">
						<div class="selected d-flex align-items-center" :style="{ height: '28px' }">
							<p class="mb-0">{{ option.name }}</p>
						</div>
					</template>
				</v-select>
			</div>
			<div class="d-flex align-items-center">
				<b-form-datepicker
					placeholder="Inicio"
					:date-format-options="{
						year: 'numeric',
						month: 'numeric',
						day: 'numeric',
					}"
					locale="es"
					v-model="date1"
					:max="date2"
					v-b-tooltip.hover
					title="Fecha de inicio"
					:style="{ width: '180px' }"
					class="mr-1"
					@input="init"
				/>
				<b-form-datepicker
					placeholder="Inicio"
					:date-format-options="{
						year: 'numeric',
						month: 'numeric',
						day: 'numeric',
					}"
					locale="es"
					v-model="date2"
					:min="date1"
					v-b-tooltip.hover
					title="Fecha final"
					:style="{ width: '180px' }"
					@input="init"
				/>
			</div>
		</div>
		<!-- {{ category }} -->
		<div class="card mt-2 mb-0 mx-auto">
			<b-table-simple class="table mb-0">
				<b-thead>
					<b-tr class="text-center">
						<b-th> # </b-th>
						<b-th> Producto </b-th>
						<b-th> Descripcion </b-th>
						<b-th> Cantidad </b-th>
						<!-- <b-th> Precio S/.</b-th>
						<b-th> Precio $ </b-th> -->
						<b-th> Importe S/. </b-th>
						<b-th> Importe $ </b-th>
						<b-th> Fecha </b-th>
						<!-- <b-th> Usuario </b-th> -->
					</b-tr>
				</b-thead>
				<b-tbody>
					<b-tr class="text-center" v-for="(item, index) in items" :key="index">
						<template v-if="items.length > 0">
							<b-td class="">
								<p class="mb-0">{{ index + 1 }}</p>
							</b-td>

							<b-td class="">
								<p class="mb-0">{{ item.name }}</p>
							</b-td>

							<b-td class="">
								<p class="mb-0">{{ item.description }}</p>
							</b-td>

							<b-td class="">
								<p class="mb-0">
									{{ parseInt(item.quantity) }}
									<!-- {{ item.um_code }} -->
								</p>
							</b-td>

							<!-- <b-td class="">
								<p class="mb-0">{{ `${parseFloat(item.amount_s).toFixed(2)}` }}</p>
							</b-td>

							<b-td class="">
								<p class="mb-0">{{ `${parseFloat(item.amount_d).toFixed(2)}` }}</p>
							</b-td> -->

							<b-td class="">
								<p class="mb-0">{{ `${parseFloat(item.amount_s).toFixed(2)}` }}</p>
							</b-td>

							<b-td class="">
								<p class="mb-0">{{ `${parseFloat(item.amount_d).toFixed(2)}` }}</p>
							</b-td>

							<b-td class="">
								<p class="mb-0">{{ item.date }}</p>
							</b-td>

							<!-- <b-td class="">
							<p class="mb-0">{{ item.um }}</p>
						</b-td> -->
						</template>
					</b-tr>
					<b-tr v-if="items.length == 0">
						<b-td colspan="7" class="text-center">
							<p class="mb-0 font-weight-bold">SIN PRODUCTOS</p>
						</b-td>
					</b-tr>
				</b-tbody>

				<b-tfoot>
					<b-tr class="text-center">
						<b-th></b-th>
						<b-th></b-th>
						<b-th></b-th>
						<b-th>Total</b-th>

						<b-th>
							{{ sumTotal(items, "amount_s").toFixed(2) }}
						</b-th>
						<b-th>
							{{ sumTotal(items, "amount_d").toFixed(2) }}
						</b-th>
						<b-th></b-th>
					</b-tr>
				</b-tfoot>
			</b-table-simple>
		</div>
	</div>
</template>

<script>
import mgtCategoryService from "@/services/mgt-category.service";
import moment from "moment";

export default {
	name: "DetailsCategory",
	props: {
		category: {
			type: Object,
			default: () => {},
		},
		dateIn: {
			type: String,
		},
		dateFn: {
			type: String,
		},
		rangeSelect: {
			type: Number,
		},
	},
	data() {
		return {
			items: [],
			ranges: [
				{
					id: 1,
					name: "Semana actual",
				},
				{
					id: 2,
					name: "Quincena",
				},
				{
					id: 3,
					name: "Mes actual",
				},
				{
					id: 4,
					name: "Año actual",
				},
			],
			reload: false,
			date1: this.dateIn,
			date2: this.dateFn,
			selectRange: this.rangeSelect,
		};
	},
	computed: {
		sumTotal: () => (items, key) => {
			return items.reduce(function (previousValue, currentValue) {
				return previousValue + parseFloat(currentValue[key]);
			}, 0);
		},
	},
	async mounted() {
		await this.init();
	},
	methods: {
		async init() {
			this.isPreloading();
			const { data } = await mgtCategoryService.getDetailsCategories({
				id: this.category.id,
				end_date: this.date2,
				start_date: this.date1,
			});
			this.items = data;
			this.isPreloading(false);
		},
		async setRanges(e) {
			switch (e) {
				case 1:
					this.date1 = moment().startOf("week").format("YYYY-MM-DD");
					this.date2 = moment().endOf("week").format("YYYY-MM-DD");
					await this.init();
					break;
				case 2:
					this.date1 = moment().startOf("month").format("YYYY-MM-DD");
					this.date2 = moment().startOf("month").add(14, "days").format("YYYY-MM-DD");
					await this.init();
					break;
				case 3:
					this.date1 = moment().startOf("month").format("YYYY-MM-DD");
					this.date2 = moment().endOf("month").format("YYYY-MM-DD");
					await this.init();
					break;
				case 4:
					this.date1 = moment().startOf("year").format("YYYY-MM-DD");
					this.date2 = moment().endOf("year").format("YYYY-MM-DD");
					await this.init();
					break;

				default:
					this.date2 = null;
					this.date1 = null;
					await this.init();
					break;
			}
			this.rangeSelect = e;
		},
	},
};
</script>
