<template>
	<div>
		<validation-observer ref="form">
			<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
				<b-form-group id="fieldset-1" label="Nombre">
					<b-form-input
						placeholder="Agregar nombre"
						id="input-1"
						v-model="category.name"
						trim
						:invalid-feedback="errors[0]"
						:state="errors[0] ? false : null"
					></b-form-input>
				</b-form-group>
			</ValidationProvider>

			<ValidationProvider rules="" v-slot="{ errors }" class="w-100">
				<b-form-group id="fieldset-1" label="Description">
					<b-form-textarea
						id="textarea"
						v-model="category.description"
						placeholder="Agregar description"
						rows="3"
						max-rows="6"
						:invalid-feedback="errors[0]"
						:state="errors[0] ? false : null"
					></b-form-textarea> </b-form-group
			></ValidationProvider>

			<ValidationProvider rules="" v-slot="{ errors }" class="w-100">
				<div class="d-flex align-items-center w-100">
					<b-form-group
						label="Categoria"
						label-for=""
						valid-feedback="Thank you!"
						:invalid-feedback="errors[0]"
						:state="errors[0] ? false : null"
						class="mb-0 w-100"
					>
						<SelectCategoryItems v-model="category.category_id" :filterBy="0"/>
					</b-form-group>
				</div>
			</ValidationProvider>
		</validation-observer>
		<div class="mt-2 d-flex justify-content-end">
			<button class="btn btn-primary" @click="saveCategory">Guardar</button>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import mgtCategoryService from "@/services/mgt-category.service";
import SelectCategoryItems from "./SelectCategoryItems.vue";

export default {
	name: "AddMgtCategory",
	components: {SelectCategoryItems},
	data() {
		return {
			categories: [],
			category: {
				name: null,
				description: null,
				category_id: null,
			},
		};
	},
	async mounted() {
		// await this.initCategories();
	},
	computed: {
		...mapState("orders", ["arrayCategory"]),
	},
	methods: {
		...mapMutations('orders', ['RELOAD_CATEGORIES']),
		async saveCategory() {
			if (!(await this.$refs.form.validate())) {
				this.showToast(
					"danger",
					"top-right",
					"Campos Incompletos",
					"AlertCircleIcon",
					"Faltan campos por llenar"
				);
				return;
			}
			this.isPreloading();

			const resp = await mgtCategoryService.createMgtCategoryByModule({
				description: this.category.description,
				name: this.category.name,
				parent_id: this.category.category_id,
				type: 1,
			});
			this.isPreloading(false);

			this.$emit("refresh");
			this.RELOAD_CATEGORIES(true);
		},
		// async initCategories() {
		// 	const { data, message, status } = await mgtCategoryService.getMgtCategoryByModule({ module_id: 1 });
		// 	this.categories = data;
		// },
	},
};
</script>

<style lang="scss">


</style>