var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-items-start container-text"},[_c('div',{staticClass:"d-flex flex-column mr-1 select h-100"},[_c('v-select',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],style:({ height: '100%', width: '180px' }),attrs:{"label":"name","options":_vm.ranges,"clearable":false,"reduce":function (option) { return option.id; },"transition":"","placeholder":"Rangos","title":"Rango"},on:{"input":function (e) { return _vm.setRanges(e); }},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-center"},[_c('p',{staticClass:"mb-0",style:({ marginLeft: (((option.level - 1) * 15) + "px") })},[_vm._v(" "+_vm._s(option.name)+" ")])])]}},{key:"selected-option",fn:function(option){return [_c('div',{staticClass:"selected d-flex align-items-center",style:({ height: '28px' })},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(option.name))])])]}}]),model:{value:(_vm.selectRange),callback:function ($$v) {_vm.selectRange=$$v},expression:"selectRange"}},[_c('template',{slot:"no-options"},[_vm._v(" Sin rangos encontrados ")])],2)],1),_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-datepicker',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1",style:({ width: '180px' }),attrs:{"placeholder":"Inicio","date-format-options":{
					year: 'numeric',
					month: 'numeric',
					day: 'numeric',
				},"locale":"es","max":_vm.date2,"title":"Fecha de inicio"},on:{"input":_vm.init},model:{value:(_vm.date1),callback:function ($$v) {_vm.date1=$$v},expression:"date1"}}),_c('b-form-datepicker',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],style:({ width: '180px' }),attrs:{"placeholder":"Inicio","date-format-options":{
					year: 'numeric',
					month: 'numeric',
					day: 'numeric',
				},"locale":"es","min":_vm.date1,"title":"Fecha final"},on:{"input":_vm.init},model:{value:(_vm.date2),callback:function ($$v) {_vm.date2=$$v},expression:"date2"}})],1)]),_c('div',{staticClass:"card mt-2 mb-0 mx-auto"},[_c('b-table-simple',{staticClass:"table mb-0"},[_c('b-thead',[_c('b-tr',{staticClass:"text-center"},[_c('b-th',[_vm._v(" # ")]),_c('b-th',[_vm._v(" Producto ")]),_c('b-th',[_vm._v(" Descripcion ")]),_c('b-th',[_vm._v(" Cantidad ")]),_c('b-th',[_vm._v(" Importe S/. ")]),_c('b-th',[_vm._v(" Importe $ ")]),_c('b-th',[_vm._v(" Fecha ")])],1)],1),_c('b-tbody',[_vm._l((_vm.items),function(item,index){return _c('b-tr',{key:index,staticClass:"text-center"},[(_vm.items.length > 0)?[_c('b-td',{},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(index + 1))])]),_c('b-td',{},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.name))])]),_c('b-td',{},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.description))])]),_c('b-td',{},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(parseInt(item.quantity))+" ")])]),_c('b-td',{},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(("" + (parseFloat(item.amount_s).toFixed(2)))))])]),_c('b-td',{},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(("" + (parseFloat(item.amount_d).toFixed(2)))))])]),_c('b-td',{},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.date))])])]:_vm._e()],2)}),(_vm.items.length == 0)?_c('b-tr',[_c('b-td',{staticClass:"text-center",attrs:{"colspan":"7"}},[_c('p',{staticClass:"mb-0 font-weight-bold"},[_vm._v("SIN PRODUCTOS")])])],1):_vm._e()],2),_c('b-tfoot',[_c('b-tr',{staticClass:"text-center"},[_c('b-th'),_c('b-th'),_c('b-th'),_c('b-th',[_vm._v("Total")]),_c('b-th',[_vm._v(" "+_vm._s(_vm.sumTotal(_vm.items, "amount_s").toFixed(2))+" ")]),_c('b-th',[_vm._v(" "+_vm._s(_vm.sumTotal(_vm.items, "amount_d").toFixed(2))+" ")]),_c('b-th')],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }