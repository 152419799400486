<template>
	<div>
		<validation-observer ref="form">
			<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
				<b-form-group id="fieldset-1" label="Nombre">
					<b-form-input
						placeholder="Agregar nombre"
						id="input-1"
						v-model="category.label"
						trim
						:invalid-feedback="errors[0]"
						:state="errors[0] ? false : null"
					></b-form-input>
				</b-form-group>
			</ValidationProvider>

			<ValidationProvider rules="" v-slot="{ errors }" class="w-100">
				<b-form-group id="fieldset-1" label="Description">
					<b-form-textarea
						id="textarea"
						v-model="category.description"
						placeholder="Agregar description"
						rows="3"
						max-rows="6"
						:invalid-feedback="errors[0]"
						:state="errors[0] ? false : null"
					></b-form-textarea> </b-form-group
			></ValidationProvider>

			<div class="d-flex align-items-center w-100">
				<b-form-group label="Categoria" label-for="" valid-feedback="Thank you!" class="mb-0 w-100">
					<SelectCategoryItems v-model="category.parent_id" :filterBy="category.id"/>
				</b-form-group>
			</div>
		</validation-observer>
		<div class="mt-2 d-flex justify-content-end">
			<button class="btn btn-primary" @click="saveCategory">Guardar</button>
		</div>
	</div>
</template>

<script>
import { mapMutations } from "vuex";
import mgtCategoryService from "@/services/mgt-category.service";
import SelectCategoryItems from "./SelectCategoryItems.vue";

export default {
	name: "EditMgtCategory",
	components: {
		SelectCategoryItems,
	},
	props: {
		item: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			categories: [
			],
			category: {
				label: null,
				description: null,
				parent_id: null,
				id: null,
			},
		};
	},
	async mounted() {
		this.category = { ...this.item };
	},
	methods: {
		...mapMutations("orders", ["RELOAD_CATEGORIES"]),
		async saveCategory() {
			if (!(await this.$refs.form.validate())) {
				this.showToast(
					"danger",
					"top-right",
					"Campos Incompletos",
					"AlertCircleIcon",
					"Faltan campos por llenar"
				);
				return;
			}

			this.isPreloading();

			const resp = await mgtCategoryService.updateMgtCategoryByModule({
				description: this.category.description,
				name: this.category.label,
				parent_id: this.category.parent_id,
				type: 1,
				id: this.category.id,
			});

			this.RELOAD_CATEGORIES(true);

			this.isPreloading(false);

			this.$emit("refresh");
		},
		// async initCategories() {
		// 	this.isPreloading();
		// 	const { data, message, status } = await mgtCategoryService.getMgtCategoryByModule({ module_id: 1 });
		// 	this.categories = data;
		// 	this.isPreloading(false);
		// },
	},
};
</script>
